var exports = {};
function _taggedTemplateLiteral(e, t) {
  return t || (t = e.slice(0)), Object.freeze(Object.defineProperties(e, {
    raw: {
      value: Object.freeze(t)
    }
  }));
}
exports = _taggedTemplateLiteral, exports.__esModule = true, exports["default"] = exports;
export default exports;
export const __esModule = exports.__esModule;